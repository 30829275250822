/* Custom CSS */
body {
  /* overflow-x: hidden; */
}

.ant-menu-horizontal .ant-menu-item {
  padding-left: 5px;
  padding-right: 5px;
}
.ant-menu-horizontal .ant-menu-item::after {
  border: none !important;
  transition: none !important;
}
.ant-menu-horizontal:hover .ant-menu-item::after {
  border: none;
  transition: none;
}

.sidebar .ant-menu-item.ant-menu-item-selected/* .ant-menu-item-only-child */ {
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  width: 100%;
}

/* .sidebar .ant-menu-item.ant-menu-item-only-child:not(:last-child) {
  height: 70px;
} */

/* .sidebar .ant-menu-item.ant-menu-item-only-child {
  padding-left: 30px !important;
} */

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background-color: transparent;
  /* border: 1px solid transparent; */
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #aaa;
}

*:hover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}

*:hover::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background-color: transparent;
  /* border: 1px solid transparent; */
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.price-card .ant-card-body {
  height: 100%;
}

.markdown-preview *{
  font-size: 14px;
}

.markdown-preview p {
  margin-bottom: 3px !important;
  color: black !important;
}

.markdown-preview p strong {
  font-size: 15px;
}

.markdown-preview table {
  overflow: auto;
}

.ant-space-compact {
  background-color: white !important;
}

.trending-section .ant-list-item-meta-avatar {
  margin-inline-end: 10px !important;
}

.message-loading .ant-spin-dot-item {
  background-color: #06cc06;
}

.bg-gray-200 > input {
  background-color: rgb(229 231 235);
}

.ant-tour {
  width: 275px !important;
}

.ant-tour-cover {
  height: 0;
}